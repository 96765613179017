import React from 'react';

const HomeDelivery = ({ className }) => {
  return (
    <svg viewBox="0 0 64 64" className={className}>
      <g>
        <path
          fill="currentColor"
          d="M61.832,36.445,58,30.7V25.939A3.549,3.549,0,0,0,60.323,19.4L52,14.237V5a1,1,0,0,0-1-1H45a1,1,0,0,0-1,1V9.271L32.527,2.15a1,1,0,0,0-1.054,0L3.677,19.4A3.547,3.547,0,0,0,6,25.943V56H3a1,1,0,0,0,0,2H32v3a1,1,0,0,0,1,1H61a1,1,0,0,0,1-1V37A1.786,1.786,0,0,0,61.832,36.445ZM48.819,36H45.181l.666-4h2.306Zm-5.666,0H34.868l2.667-4H43.82ZM45,38h4v7.131l-1.445-.963a1,1,0,0,0-1.11,0L45,45.131Zm5.847-2-.667-4h6.285l2.667,4ZM46,6h4v7l-4-2.483ZM4.8,23.771A1.548,1.548,0,0,1,4.732,21.1L32,4.177,59.268,21.1a1.549,1.549,0,0,1-1.634,2.632L32.527,8.15a1,1,0,0,0-1.054,0L6.366,23.734A1.528,1.528,0,0,1,4.8,23.771ZM14,56V34H26V56Zm14,0V33a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V56H8V25.073l24-14.9,24,14.9V30H37a1,1,0,0,0-.832.445l-4,6A1.786,1.786,0,0,0,32,37V56Zm32,4H34V38h9v9a1,1,0,0,0,1.555.832L47,46.2l2.445,1.63A1,1,0,0,0,51,47V38h9Z"
        />
        <path fill="currentColor" d="M24,44H23a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Z" />
        <path
          fill="currentColor"
          d="M38,21a6,6,0,1,0-6,6A6.006,6.006,0,0,0,38,21Zm-5,3.858V22h2.858A4,4,0,0,1,33,24.858ZM35.858,20H33V17.142A4,4,0,0,1,35.858,20ZM31,17.142V20H28.142A4,4,0,0,1,31,17.142ZM28.142,22H31v2.858A4,4,0,0,1,28.142,22Z"
        />
      </g>
    </svg>
  );
};

export default HomeDelivery;
