import React from 'react';

const Sprout = ({ className }) => {
  return (
    <svg viewBox="0 0 512.001 512.001" className={className}>
      <path
        fill="currentColor"
        d="M436.551,34.105c-2.936-3.008-7.754-3.07-10.761-0.136l-50.928,49.663l6.202-30.905c0.826-4.119-1.843-8.131-5.964-8.958
			c-4.116-0.822-8.132,1.843-8.958,5.964l-10.581,52.719l-21.826,21.284l6.059-30.191c0.827-4.119-1.843-8.131-5.964-8.958
			c-4.119-0.825-8.132,1.843-8.958,5.964l-10.437,52.006l-39.577,38.592c-3.009,2.934-3.069,7.752-0.136,10.761
			c1.492,1.528,3.469,2.296,5.45,2.296c1.916,0,3.832-0.718,5.312-2.161l37.172-36.248l55.814-0.953
			c4.203-0.072,7.55-3.536,7.479-7.739c-0.071-4.159-3.464-7.48-7.606-7.48c-0.045,0-0.089,0-0.133,0.001l-39.666,0.677
			l25.98-25.334l55.215-0.944c4.203-0.072,7.55-3.536,7.479-7.739c-0.071-4.159-3.464-7.48-7.606-7.48c-0.045,0-0.089,0-0.133,0.001
			l-39.067,0.668l56.003-54.611C439.425,41.93,439.485,37.113,436.551,34.105z"
      />

      <path
        fill="currentColor"
        d="M126.342,143.504c-2.437-2.767-4.805-5.642-7.039-8.546c-2.561-3.33-7.338-3.956-10.672-1.393
			c-3.331,2.562-3.955,7.34-1.393,10.672c2.438,3.171,5.023,6.31,7.683,9.328c1.505,1.707,7.15,4.367,10.742,0.68
			C128.595,151.234,129.12,146.657,126.342,143.504z"
      />

      <path
        fill="currentColor"
        d="M292.81,391.259c-13.704,0-24.852,11.149-24.852,24.852c0,13.704,11.149,24.852,24.852,24.852
			c13.704,0,24.852-11.149,24.852-24.852C317.663,402.408,306.514,391.259,292.81,391.259z M292.81,425.745
			c-5.312,0-9.633-4.321-9.633-9.633c0-5.312,4.321-9.633,9.633-9.633c5.312,0,9.633,4.321,9.633,9.633
			C302.443,421.423,298.122,425.745,292.81,425.745z"
      />

      <path
        fill="currentColor"
        d="M362.338,431.864c-4.204,0-7.61,3.406-7.61,7.61s3.406,7.61,7.61,7.61c5.312,0,9.634,4.321,9.634,9.633
			c0,5.312-4.322,9.634-9.634,9.634c-5.312,0-9.633-4.322-9.633-9.634c0-4.204-3.406-7.61-7.61-7.61c-4.204,0-7.61,3.406-7.61,7.61
			c0,13.705,11.149,24.853,24.852,24.853c13.705,0,24.853-11.149,24.853-24.853C387.191,443.013,376.042,431.864,362.338,431.864z"
      />

      <path
        fill="currentColor"
        d="M379.581,290.544c-13.703,0-24.852,11.149-24.852,24.853c0,13.704,11.149,24.852,24.852,24.852
			c13.704,0,24.852-11.149,24.852-24.852C404.433,301.692,393.285,290.544,379.581,290.544z M379.582,325.03
			c-5.312,0-9.633-4.321-9.633-9.633c0-5.312,4.321-9.634,9.633-9.634c5.312,0,9.633,4.321,9.633,9.634
			C389.215,320.708,384.894,325.03,379.582,325.03z"
      />

      <path
        fill="currentColor"
        d="M115.778,284.456c-13.704,0-24.852,11.149-24.852,24.853c0,13.704,11.149,24.852,24.852,24.852
			c4.204,0,7.61-3.406,7.61-7.61s-3.406-7.61-7.61-7.61c-5.312,0-9.633-4.321-9.633-9.633s4.321-9.634,9.633-9.634
			c5.312,0,9.633,4.322,9.633,9.634c0,4.204,3.406,7.61,7.61,7.61c4.204,0,7.61-3.406,7.61-7.61
			C140.63,295.604,129.482,284.456,115.778,284.456z"
      />

      <path
        fill="currentColor"
        d="M443.45,397.934c-5.741,0-11.396,1.032-16.711,3.021c-20.826-51.512-76.986-47.893-78.993-47.741
			c-13.75-27.283-38.771-46.738-67.887-53.694c0.802-25.649,3.14-50.553,7.004-74.153c17.73-1.7,52.138-7.732,87.564-28.975
			c25.403-15.234,46.525-35.487,62.776-60.195c19.524-29.687,31.901-65.746,36.787-107.172c0.907-7.676-1.622-15.297-6.938-20.909
			c-5.307-5.604-12.805-8.542-20.504-8.064c-1.955,0.125-48.416,3.337-95.661,28.796c-32.817,17.683-60.407,46.872-79.996,84.037
			c-3.944-8.079-8.321-15.809-13.101-23.076c-16.253-24.71-37.375-44.962-62.777-60.195C146.19,0.337,98.487,0,96.481,0
			c-3.371,0-12.324,0-20.03,7.71c-5.058,5.06-7.825,12.064-7.589,19.213c1.12,34.354,8.917,65.355,23.176,92.141
			c1.973,3.708,6.579,5.117,10.293,3.141c3.709-1.974,5.116-6.583,3.141-10.293C92.315,87.197,85.115,58.436,84.072,26.425
			c-0.097-2.961,1.048-5.861,3.143-7.957c3.247-3.249,7.015-3.249,9.267-3.249c1.835,0,45.453,0.313,90.705,27.448
			c23.427,14.047,42.902,32.722,57.888,55.504c6.6,10.035,12.399,21.027,17.244,32.673c-2.583,6.006-4.981,12.176-7.165,18.512
			c-1.501,4.419-3.194,9.773-4.97,16.022l-23.402-22.821l-10.437-52.006c-0.826-4.121-4.836-6.789-8.958-5.964
			c-4.121,0.827-6.79,4.839-5.964,8.958l6.059,30.191l-21.827-21.284l-10.58-52.719c-0.826-4.121-4.838-6.787-8.958-5.964
			c-4.121,0.828-6.79,4.838-5.964,8.958l6.202,30.906l-50.93-49.665c-3.011-2.934-7.826-2.872-10.761,0.136
			c-2.934,3.009-2.873,7.827,0.136,10.76l56.003,54.611l-39.067-0.668c-4.199-0.115-7.667,3.276-7.739,7.479
			c-0.071,4.203,3.276,7.667,7.479,7.739l55.215,0.944l25.979,25.334l-39.665-0.677c-4.164-0.093-7.667,3.276-7.739,7.479
			c-0.071,4.203,3.276,7.667,7.479,7.739l55.813,0.953l27.208,26.531c-1.733,7.261-3.46,15.298-5.092,24.13
			c-16.494-3.639-39.117-10.378-61.981-22.697c-11.534-6.215-22.269-13.422-31.906-21.421c-3.234-2.685-8.032-2.24-10.716,0.994
			c-2.685,3.234-2.239,8.031,0.994,10.715c10.411,8.643,21.988,16.418,34.407,23.109c24.703,13.312,49.077,20.492,66.65,24.32
			c-3.433,22.108-6.101,48.257-6.929,78.259c-28.712,7.148-53.338,26.486-66.939,53.475c-2.008-0.152-55.376-5.896-78.993,47.741
			c-5.314-1.989-10.97-3.021-16.711-3.021c-26.278,0-47.658,21.379-47.658,47.658s21.379,47.658,47.658,47.658
			c10.451,0,20.553-3.454,28.773-9.677c15.034,17.943,37.227,28.428,60.924,28.428c19.743,0,38.316-7.12,52.893-20.161
			c13.919,6.758,29.313,10.31,44.858,10.31c15.545,0,30.939-3.551,44.858-10.31c14.577,13.042,33.151,20.161,52.894,20.161
			c23.697,0,45.891-10.485,60.924-28.428c8.221,6.223,18.323,9.677,28.773,9.677c26.278,0,47.658-21.379,47.658-47.658
			S469.728,397.934,443.45,397.934z M269.553,154.283c17.563-50.942,49.012-90.731,88.554-112.038
			c44.347-23.898,87.589-26.89,89.395-27.004c3.21-0.195,6.303,1.02,8.501,3.34c2.202,2.325,3.249,5.48,2.873,8.661
			c-4.606,39.056-16.176,72.901-34.39,100.593c-14.985,22.782-34.461,41.457-57.888,55.505
			c-38.207,22.91-76.005,26.69-86.681,27.302c-3.525,0.202-6.447,2.802-7.059,6.279c-4.471,25.426-7.192,52.37-8.146,80.164
			c-2.882-0.245-5.785-0.385-8.714-0.385c-3.197,0-6.368,0.163-9.51,0.455C248.905,222.555,263.324,172.625,269.553,154.283z
			 M443.45,478.03c-9.204,0-18.011-3.938-24.162-10.803c-1.573-1.754-3.873-2.678-6.211-2.512c-2.347,0.167-4.485,1.412-5.789,3.37
			c-11.974,17.969-31.986,28.696-53.535,28.696c-17.51,0-33.884-6.929-46.107-19.51c-1.471-1.515-3.454-2.307-5.462-2.307
			c-1.263,0-2.537,0.315-3.699,0.961c-12.933,7.201-27.624,11.007-42.483,11.007s-29.55-3.806-42.483-11.007
			c-3.009-1.675-6.763-1.121-9.16,1.346c-12.222,12.581-28.597,19.51-46.107,19.51c-21.548,0-41.561-10.727-53.535-28.696
			c-1.305-1.958-3.443-3.202-5.789-3.37c-2.333-0.164-4.639,0.759-6.211,2.512c-6.152,6.866-14.959,10.803-24.162,10.803
			c-17.887,0-32.439-14.552-32.439-32.439s14.552-32.439,32.439-32.439c6.002,0,11.863,1.653,16.951,4.78
			c2.008,1.235,4.471,1.466,6.676,0.635c2.203-0.833,3.897-2.641,4.586-4.894c8.313-27.198,33.022-45.471,61.484-45.471
			c3.135,0,6.32,0.234,9.464,0.699c3.394,0.496,6.709-1.333,8.084-4.481c13.941-31.895,45.424-52.503,80.203-52.503
			c34.779,0,66.262,20.609,80.203,52.503c1.374,3.146,4.685,4.979,8.084,4.481c3.145-0.465,6.329-0.699,9.464-0.699
			c28.462,0,53.171,18.273,61.484,45.471c0.689,2.253,2.383,4.061,4.586,4.894c2.206,0.832,4.669,0.6,6.676-0.635
			c5.088-3.127,10.95-4.78,16.951-4.78c17.887,0,32.439,14.552,32.439,32.439S461.337,478.03,443.45,478.03z"
      />
      <circle fill="currentColor" cx="350.717" cy="397.713" r="6.635" />
      <circle fill="currentColor" cx="417.317" cy="353.953" r="6.635" />
      <circle fill="currentColor" cx="86.133" cy="347.317" r="6.635" />
    </svg>
  );
};

export default Sprout;
