import React, { useContext } from 'react';
import { Button, Link } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';

import SpinnerContext from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import Input from '../Input';
import { REAL_ESTATE_INTEREST_FORM } from './query';
import Section from '../Section';

const RealEstateFormSchema = Yup.object().shape({
  companyName: Yup.string().required('Ergänzen Sie Ihre Angaben'),
  firstName: Yup.string().required('Ergänzen Sie Ihre Angaben'),
  lastName: Yup.string().required('Ergänzen Sie Ihre Angaben'),
  phone: Yup.string().required('Ergänzen Sie Ihre Angaben'),
  email: Yup.string().email('Korrigieren Sie Ihre Angabe').required('Ergänzen Sie Ihre Angaben'),
});

export const RealEstateFormSection = () => {
  const [realEstateInterestForm] = useMutation(REAL_ESTATE_INTEREST_FORM);
  const toast = useCustomToast();
  const { toggleSpinner } = useContext(SpinnerContext);

  return (
    <Section fullWidth className="py-16 md:py-24 px-8 md:px-16" id="realestate-form">
      <div className="max-w-lg mx-auto">
        <div className="flex flex-col">
          <div className="text-center">
            <h2 className="font-display w-full mb-4 md:mb-8 text-2xl lg:text-3xl font-bold leading-tight text-gray-800">
              Bereit für Smart City: Ihre Nachricht ans Team.
            </h2>
            <p className="mb-8">
              Wir bei DropFriends sind gespannt auf Ihre Anfrage! Egal, ob Sie neugierig auf unsere
              innovativen Lösungen für die Paketzustellung in Quartieren sind oder konkrete Fragen
              haben – wir sind hier, um Sie zu unterstützen. Mit einem freundlichen Team und einer
              offenen Plattform heißen wir Sie willkommen. Teilen Sie uns Ihre Kontaktinformationen
              über das untenstehende Formular mit, damit wir bald ins Gespräch kommen können.
            </p>
          </div>
          <Formik
            initialValues={{
              companyName: '',
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              contactProfession: '',
            }}
            validationSchema={RealEstateFormSchema}
            onSubmit={async ({
              companyName,
              email,
              firstName,
              lastName,
              phone,
              contactProfession,
            }) => {
              toggleSpinner(true);
              try {
                await realEstateInterestForm({
                  variables: {
                    companyName,
                    email,
                    firstName,
                    lastName,
                    phone,
                    contactProfession,
                  },
                });
                toast({
                  title: 'Vielen Dank! 🎉',
                  description: 'Wir haben Ihre Anfrage erhalten.',
                  status: 'success',
                });
              } catch (error) {
                console.log(error);
                toast({
                  description: 'Ein Fehler ist aufgetreten!',
                  status: 'error',
                });
              }
              toggleSpinner(false);
            }}>
            <Form>
              <Input name="companyName" isRequired type="text" label="Unternehmensname*" />
              <div className="grid grid-cols-2 gap-4">
                <Input name="firstName" isRequired label="Vorname*" />
                <Input name="lastName" isRequired label="Nachname*" />
              </div>
              <Input name="email" isRequired type="email" label="E-Mail Adresse*" />
              <Input name="phone" label="Telefonnummer*" />
              <Input name="contactProfession" label="Jobbezeichnung (optional)" />
              <p className="text-xs mb-6">*Pflichtfeld</p>
              <p className="text-sm text-gray-600 mb-4">
                Ich stimme der Verarbeitung und Speicherung meiner Daten gemäß der{' '}
                <Link href="/Datenschutzerklärung_16.03.2022.pdf" target="_blank">
                  Datenschutzbestimmungen
                </Link>{' '}
                zu.
              </p>

              <Button colorScheme="brand" variant="solid" width="full" type="submit">
                Jetzt absenden
              </Button>
            </Form>
          </Formik>
        </div>
      </div>
    </Section>
  );
};
