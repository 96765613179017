import gql from 'graphql-tag';

export const REAL_ESTATE_INTEREST_FORM = gql`
  mutation REAL_ESTATE_INTEREST_FORM(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $companyName: String
    $contactProfession: String
  ) {
    realEstateInterestForm(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      companyName: $companyName
      contactProfession: $contactProfession
    ) {
      id
    }
  }
`;
