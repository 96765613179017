import React from 'react';

const Friendship = ({ className }) => {
  return (
    <svg
      viewBox="0 0 512 512"
      className={className}
      // style="enable-background:new 0 0 512 512;"
    >
      <path
        fill="currentColor"
        d="M424.16,222.53c-3.84-1.557-8.212,0.293-9.77,4.131c-4.204,10.365-14.159,17.063-25.362,17.063
        c-7.306,0-14.185-2.849-19.363-8.015c-2.612-2.612-4.629-5.652-5.995-9.037c-1.551-3.84-5.921-5.699-9.763-4.147
        c-3.842,1.55-5.698,5.92-4.148,9.762c2.125,5.264,5.254,9.984,9.306,14.035c8.016,7.998,18.657,12.402,29.963,12.402
        c17.34,0,32.752-10.373,39.263-26.425C429.848,228.461,427.998,224.087,424.16,222.53z"
      />
      <path
        fill="currentColor"
        d="M345.923,171.145c-4.143,0-7.5,3.358-7.5,7.5v4.159c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-4.159
        C353.423,174.503,350.066,171.145,345.923,171.145z"
      />
      <path
        fill="currentColor"
        d="M432.131,171.145c-4.143,0-7.5,3.358-7.5,7.5v4.159c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-4.159
        C439.631,174.503,436.274,171.145,432.131,171.145z"
      />
      <path
        fill="currentColor"
        d="M166.954,222.529c-3.841-1.557-8.212,0.293-9.77,4.131c-4.204,10.365-14.159,17.063-25.362,17.063
        c-7.306,0-14.185-2.849-19.363-8.014c-2.613-2.614-4.631-5.654-5.995-9.036c-1.55-3.841-5.919-5.7-9.761-4.149
        c-3.842,1.55-5.699,5.92-4.149,9.761c2.123,5.262,5.251,9.982,9.305,14.037c8.017,7.997,18.658,12.401,29.963,12.401
        c17.34,0,32.752-10.373,39.263-26.425C172.642,228.46,170.792,224.086,166.954,222.529z"
      />
      <path
        fill="currentColor"
        d="M88.718,171.145c-4.143,0-7.5,3.358-7.5,7.5v4.159c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-4.159
        C96.218,174.503,92.861,171.145,88.718,171.145z"
      />
      <path
        fill="currentColor"
        d="M174.926,171.145c-4.143,0-7.5,3.358-7.5,7.5v4.159c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5v-4.159
        C182.426,174.503,179.069,171.145,174.926,171.145z"
      />
      <path
        fill="currentColor"
        d="M504.5,129.4c4.143,0,7.5-3.358,7.5-7.5v-20.81c0-29.561-24.05-53.61-53.61-53.61h-10.083l-1.224-2.83
        c-6.091-14.069-19.932-23.16-35.263-23.16h-49.75c-52.946,0-96.021,43.074-96.021,96.02v153.14c0,22.2,18.061,40.26,40.26,40.26
        h42.9v10.59H187.26h-15.63v-14.314c9.003-4.235,17.361-9.914,24.74-16.944c15.672-14.929,25.56-34.523,28.283-55.779
        c15.213-0.015,27.584-12.396,27.584-27.613v-9.514c0-6.733-2.428-12.906-6.446-17.703l17.261-68.316
        c1.015-4.016-1.418-8.094-5.435-9.109c-4.01-1.011-8.094,1.418-9.108,5.434l-15.979,63.241c-2.26-0.677-4.637-1.077-7.098-1.148
        v-25.313c0-2.379-1.129-4.617-3.042-6.031c-1.913-1.414-4.386-1.838-6.658-1.139c-41.101,12.609-77.123,11.943-104.17-1.931
        c-20.262-10.394-28.667-24.724-28.73-24.834c-1.67-2.975-5.141-4.447-8.438-3.584c-3.301,0.863-5.603,3.845-5.603,7.256
        c0,18.578-3.966,22.603-23.575,23.926c-3.938,0.266-6.995,3.537-6.995,7.483v24.168c-2.734,0.078-5.369,0.558-7.851,1.379
        c-3.239-12.275-5.295-21.383-5.879-26.1c-1.167-9.385-0.358-37.367,0.052-47.356c0.004-0.103,0.006-0.205,0.006-0.308
        c0-12.28,9.99-22.27,22.271-22.27h11.37c1.989,0,3.896-0.79,5.304-2.197l26.828-26.83c5.433-5.432,13.444-7.699,20.912-5.917
        l94.337,22.439c4.971,1.184,10.084,1.784,15.198,1.784c14.799,0,29.325-5.076,40.902-14.292c0.187-0.149,0.42-0.337,0.874-0.043
        c0.453,0.292,0.38,0.58,0.322,0.809l-5.68,22.48c-1.015,4.016,1.418,8.094,5.435,9.109c4.015,1.015,8.095-1.418,9.108-5.434
        l5.681-22.482c1.68-6.658-0.965-13.365-6.736-17.087c-5.774-3.724-12.976-3.364-18.347,0.915
        c-8.933,7.111-20.141,11.027-31.56,11.027c-3.945,0-7.89-0.463-11.725-1.376L114.71,25.544
        c-12.496-2.981-25.904,0.813-34.994,9.902L55.084,60.08H46.82c-20.497,0-37.183,16.632-37.271,37.109
        c-0.117,2.91-1.468,37.417,0.057,49.672c0.768,6.21,3.359,17.461,7.7,33.453c-3.685,4.694-5.89,10.604-5.89,17.023v9.514
        c0,15.215,12.364,27.596,27.569,27.613c4.141,32.417,24.755,59.425,53.024,72.716v14.32H76.39c-4.582,0-9.097,0.507-13.498,1.5
        c-3.661-6.401-10.544-10.734-18.432-10.734H26.195C11.751,312.266,0,324.017,0,338.461v40.803c0,10.542,6.263,19.643,15.26,23.794
        v79.951c0,4.142,3.357,7.5,7.5,7.5h218.13c4.143,0,7.5-3.358,7.5-7.5V382.63c0-18.4-8.183-34.914-21.087-46.13H349.21v3.74
        c0,21.632,32.468,40.348,36.17,42.411c1.135,0.632,2.393,0.949,3.65,0.949c1.259,0,2.517-0.316,3.651-0.949
        c3.701-2.063,36.158-20.78,36.158-42.411v-3.74h15.62c25.437,0,46.13,20.694,46.13,46.13v92.88h-21.022v-50.25
        c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50.25H323.49v-86.26c0-4.142-3.357-7.5-7.5-7.5H271c-4.143,0-7.5,3.358-7.5,7.5
        c0,4.142,3.357,7.5,7.5,7.5h37.49v86.26c0,4.142,3.357,7.5,7.5,7.5h182.1c4.143,0,7.5-3.358,7.5-7.5V382.63
        c0-33.707-27.423-61.13-61.13-61.13h-15.62v-10.59h42.91c22.194,0,40.25-18.061,40.25-40.26V157.31c0-4.142-3.357-7.5-7.5-7.5
        s-7.5,3.358-7.5,7.5v16.975c-4.149-2.742-9.074-4.4-14.373-4.551v-36.16c0-17.237-5.187-33.803-15-47.908
        c-1.194-1.718-3.053-2.858-5.125-3.146c-2.073-0.289-4.171,0.304-5.789,1.631c-32.938,27.027-74.579,41.911-117.253,41.911
        c-11.826,0-23.615-1.118-35.04-3.323c-2.128-0.41-4.337,0.121-6.046,1.46s-2.754,3.353-2.865,5.521
        c-0.063,1.22-0.093,2.481-0.093,3.854v36.161c-5.296,0.153-10.218,1.811-14.366,4.551V117.51c0-44.674,36.346-81.02,81.021-81.02
        h49.75c9.346,0,17.784,5.542,21.496,14.118l3.18,7.35c1.188,2.745,3.893,4.522,6.884,4.522h15.01c21.29,0,38.61,17.32,38.61,38.61
        v20.81C497,126.042,500.357,129.4,504.5,129.4z M53.329,226.576c-0.206-3.986-3.498-7.113-7.49-7.113h-6.822
        c-6.948,0-12.601-5.658-12.601-12.613v-9.514c0-6.955,5.652-12.613,12.601-12.613h6.705c4.143,0,7.5-3.358,7.5-7.5v-24.891
        c15.63-2.231,24.427-8.32,28.249-20.189c5.344,5.164,12.666,10.96,22.271,16.017c35.02,18.437,75.794,14.311,106.69,6.194v22.869
        c0,4.142,3.357,7.5,7.5,7.5h6.692c6.954,0,12.612,5.658,12.612,12.613v9.514c0,6.955-5.658,12.613-12.612,12.613h-6.822
        c-3.991,0-7.283,3.125-7.49,7.111c-1.046,20.129-9.672,38.883-24.288,52.807c-7.27,6.926-15.665,12.305-24.749,15.975
        c-0.047,0.019-0.093,0.039-0.14,0.06c-9.212,3.701-19.13,5.644-29.309,5.644c-10.343,0-20.232-2.004-29.297-5.642
        c-0.141-0.062-0.287-0.116-0.432-0.17C74.696,284.063,54.946,257.859,53.329,226.576z M156.63,312.715V329v11.24
        c0,6.631-2.579,12.862-7.264,17.546c-4.683,4.684-10.914,7.263-17.546,7.263c-13.681,0-24.811-11.129-24.811-24.81V329v-16.28
        c7.913,2.168,16.232,3.34,24.818,3.34C140.309,316.06,148.633,314.905,156.63,312.715z M15,379.265v-40.804
        c0-6.173,5.022-11.195,11.195-11.195H44.46c3.438,0,6.234,2.797,6.234,6.235v50.724c0,3.438-2.797,6.235-6.234,6.235H26.195
        C20.022,390.46,15,385.438,15,379.265z M233.39,382.63v92.88h-21.027v-50.25c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50.25
        H66.281v-50.25c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50.25H30.26v-70.05h14.2c11.709,0,21.234-9.526,21.234-21.235
        v-46.472c3.478-0.826,7.056-1.253,10.695-1.253h15.62v3.74c0,21.951,17.859,39.81,39.811,39.81
        c10.639,0,20.637-4.14,28.153-11.657c7.517-7.517,11.656-17.515,11.656-28.153v-3.74h15.63
        C212.696,336.5,233.39,357.194,233.39,382.63z M413.84,340.24c0,8.314-13.497,20.06-24.81,27.142
        c-11.321-7.084-24.82-18.829-24.82-27.142v-27.52c7.911,2.168,16.228,3.339,24.812,3.339c8.488,0,16.817-1.156,24.818-3.347
        V340.24z M497,229.903v40.747h0c0,13.929-11.327,25.26-25.25,25.26h-24.706c2.251-1.782,4.434-3.669,6.535-5.67
        c15.67-14.938,25.558-34.533,28.281-55.777C487.449,234.457,492.651,232.776,497,229.903z M296.223,184.723h6.692
        c4.143,0,7.5-3.358,7.5-7.5v-38.259c9.558,1.394,19.292,2.099,29.044,2.099c43.271,0,85.561-14.157,120.028-40.025
        c5.345,9.96,8.139,21.066,8.139,32.537v43.649c0,4.142,3.357,7.5,7.5,7.5h6.705c6.948,0,12.601,5.658,12.601,12.613v9.514
        c0,6.955-5.652,12.613-12.601,12.613h-6.822c-3.991,0-7.283,3.125-7.49,7.111c-1.045,20.118-9.672,38.873-24.288,52.807
        c-7.235,6.892-15.586,12.25-24.621,15.919c-0.133,0.052-0.264,0.108-0.394,0.168c-9.179,3.669-19.058,5.592-29.195,5.592
        c-10.339,0-20.225-2.003-29.287-5.64c-0.145-0.064-0.294-0.119-0.443-0.174c-27.394-11.186-47.139-37.389-48.755-68.67
        c-0.206-3.986-3.498-7.113-7.49-7.113h-6.822c-6.954,0-12.612-5.658-12.612-12.613v-9.515h-0.001
        C283.611,190.381,289.269,184.723,296.223,184.723z M296.192,234.463c3.186,24.946,16.131,46.688,34.804,61.447H306.31
        c-13.929,0-25.26-11.332-25.26-25.26v-40.746C285.399,232.776,290.602,234.457,296.192,234.463z"
      />
    </svg>
  );
};

export default Friendship;
