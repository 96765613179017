import React from 'react';
import { Link, graphql } from 'gatsby';

import { ImageTextRow } from '../components/ImageTextRow';
import PageLayout from '../components/PageLayout';
import SEO from '../components/seo';
import { Hero } from '../components/Hero';
import CompanyTestimonials from '../components/CompanyTestimonials';
import Section from '../components/Section';
import { Testimonials } from '../components/Testimonials';
import Advantage, { AdvantageRow } from '../components/Advantage';
import getEnvVars from '../environment';
import FAQAccordion from '../components/FAQAccordion';
import { Press } from '../components/Press';
import {
  VideoSection,
  VideoContainer,
  VideoWrapper,
  DropPointSection,
  DropPointHeader,
  RegisterLink,
  AdvantageHeader,
  AdvantageContent,
  RegisterLinkContainer,
  FAQSection,
  FAQWrapper,
  FAQHeader,
  FAQSubHeader,
  BiSearchAltIcon,
  AiOutlineCheckCircleIcon,
  VscRocketIcon,
  HiStarIcon,
  RegisterLinkContainerAdvantages,
} from '../styles/paketshop-werden';
import { useTracking } from '../hooks/useTracking';
import { Helmet } from 'react-helmet';
import Friendship from '../svgs/Friendship';
import Sprout from '../svgs/Sprout';
import HomeDelivery from '../svgs/HomeDelivery';
import { RealEstateFormSection } from '../components/RealEstateForm';

const { webAppUrl } = getEnvVars();

const B2BPage = ({
  data: {
    Hero1,
    Hero2,
    Hero3,
    Hero4,
    Hero5,
    ImageGutschein,
    ImagePakete,
    ImageKleiderstange,
    Business3,
    Business4,
    Sprint3,
  },
}) => {
  const { trackEvent } = useTracking();
  const images = [Hero1, Hero2, Hero3, Hero4, Hero5];

  return (
    <PageLayout>
      <SEO title="Unsere Angebote für Dein Unternehmen" />
      <Helmet>
        <script>
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3054198,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <Hero
        images={images}
        title={
          <>
            PropTech für Paket Management für Immobilien
            <br />
            Ohne Paketbox-Installation!
          </>
        }
        subTitle="Funktioniert mit allen Paketdienstleistern."
        fullscreen
        leftColumn={
          <VideoSection>
            <div className="w-3/4 flex justify-center">
              <VideoWrapper>
                <VideoContainer>
                  <iframe
                    // width="2032"
                    // height="400"
                    src="https://www.youtube.com/embed/hHzelIFklVY"
                    title="Video über DropFriends"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </VideoContainer>
              </VideoWrapper>
            </div>
          </VideoSection>
        }>
        <div className="flex items-center mt-4">
          {/* <HiArrowRight className="h-12 w-12 mr-4" /> */}
          <Link
            to={`/anwohner-paketshop#realestate-form`}
            rel="noreferrer"
            className={
              'text-2xl font-display font-bold tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer'
            }>
            Infos anfordern
          </Link>
        </div>
      </Hero>
      <CompanyTestimonials />

      <AdvantageRow
        title="Die neue Freiheit beim Paketempfang"
        subTitle="Für Wohnungsbaugesellschaften, Immobilienentwicklungen & Städte."
        description={
          <div className="text-center max-w-4xl mx-auto mb-8">
            <p>
              6 Pakete pro Sekunde können in Deutschland beim ersten Versuch nicht durch Paketboten
              zugestellt werden. Eine Herausforderung für die innerstädtische Logistik und die
              Nachbarschaftsdynamik.
            </p>
            <p>
              Die innovative Plattform ermöglicht es, den Empfang von Paketen in Einklang mit
              ökologischen, sozialen und ökonomischen Prinzipien zu gestalten - und das ganz ohne
              aufwändige Hardwareinstallation oder -wartung. Mit DropFriends ist man Vorreiter auf
              dem Weg zur Smart City und bietet Anwohnern ein modernes, effizientes und
              umweltfreundliches Empfangsmanagement.
            </p>
          </div>
        }
        titleId="vorteile"
        className="justify-center">
        <Advantage
          icon={<HomeDelivery className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Zentraler Paketempfang für Anwohner">
          <p>
            Pakete werden zentral für Anwohner empfangen, verwaltet und sicher übergeben. Die
            Bewohner wissen genau, wann und wo ihr Paket angekommen ist, in welchem Zustand es sich
            befindet und der Abholzeitraum wird ebenfalls über die App mitgeteilt.
          </p>
        </Advantage>
        <Advantage icon={<BiSearchAltIcon />} title="Sicherheit und Komfort">
          <p>
            Schluss mit unbeaufsichtigten Paketen in Fluren. Keine Paket-Diebstähle oder
            Stolperfallen. Fragen zum Brandschutz und zur Haftung sind obsolet. Pakete werden im
            Quartier oder direkt in Räumlichkeiten des Komplexes bequem verwaltet.
          </p>
        </Advantage>
        <Advantage icon={<VscRocketIcon />} title="Sofort einsatzbereit. Ohne Einschränkung.">
          <p>
            Unmittelbar nach der Registrierung ist die Online-Software und App von DropFriends
            einsatzbereit. Eine Installation von Paketboxen auf wertvollen Stellflächen ist nicht
            notwendig. Eine aufwendige Reinigung und Wartung entfallen.
          </p>
        </Advantage>
        <Advantage
          icon={<Sprout className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Lieferverkehr in der Innenstadt reduzieren">
          <p>
            Durch die Zusammenführung der Lieferrouten, kann in der Paketzustellung 800 % an
            Einzelfahrten pro Haushalt eingespart werden. * Mehrfahrten auf Straßen werden
            reduziert, die CO2 Emission der Immobilien minimiert und eine Entlastung von
            Stadtvierteln und -quartieren ist die Folge.
          </p>
          <p className="text-xs text-left text-gray-500 mb-0">
            * Eva Sprengnetter, Oliver Wyman | E-Commerce mit CO2-Effizienz durch Lieferbündelung |
            2021
          </p>
        </Advantage>
        <Advantage icon={<AiOutlineCheckCircleIcon />} title="Mehr Flexibilität ">
          <p>
            Alle Paketdienstleister unter Ihrem Dach. Sie bestimmen über die mögliche Anzahl von
            Paketen und derer maximalen Größe. Eine Überbuchung Ihrer Flächen findet nicht statt.
            Das Anpassen Ihrer Annahmekriterien ist jederzeit möglich; inklusive Ihrer individuellen
            Abholzeiten fürs Stadtviertel.
          </p>
        </Advantage>

        <Advantage
          icon={<Friendship className="mx-auto w-16 h-16 mb-4 text-primary-500" />}
          title="Sozial und fair">
          <p>
            Haushalte, die Verwaltung mit Portier und das integrierte Gewerbe wird als
            Empfangsstelle zur Verfügung gestellt. Eine echte Förderung der Nachbarschaft und
            Erhöhung der Laufkundschaft für Ladenlokale. Umsätze steigen m.H. der praktischen
            Coupon-Lösung und Haushalte optimieren ihre Haushaltskasse.
          </p>
        </Advantage>
      </AdvantageRow>

      <DropPointSection fullWidth>
        <DropPointHeader>Bereits 1500+ unabhängige DropPoints</DropPointHeader>
        <RegisterLinkContainer tw={'justify-center m-0'}>
          <Link
            to={`/anwohner-paketshop#realestate-form`}
            rel="noreferrer"
            className={
              'text-xl font-display font-bold tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer'
            }>
            Infos anfordern
          </Link>
        </RegisterLinkContainer>
      </DropPointSection>

      <FAQSection>
        <FAQWrapper>
          <div className="text-center mb-12">
            <FAQHeader>FAQ</FAQHeader>
            <FAQSubHeader>Eure Fragen. Unsere Antworten.</FAQSubHeader>
          </div>
          <FAQAccordion headerBg="bg-white" question="Was ist ein DropPoint?">
            <p className="mb-4">
              DropPoints sind Annahmestellen von Paketsendungen, die z.B. die Einwohner Deines
              Stadtviertels wegen Arbeitszeiten oder Terminen nicht selbst entgegennehmen können.
            </p>
            <p className="mb-4">
              Mit Hilfe von DropPopints sind Abholzeiten bereits vor der Bestellung von
              Online-Einkäufen geregelt. So können Sendungseigentümer ihren Wunsch-DropPoint ganz
              nach ihren Bedürfnissen und Alltagsplänen auswählen. Das spart Zeit, ist
              umweltfreundlich und sichert die Erstzustellung von Sendungen.
            </p>
            <p>
              Somit entscheidet nicht mehr der Paketbote was mit dem Eigentum Anderer geschieht. Das
              ist nur fair.
            </p>
          </FAQAccordion>
          <FAQAccordion headerBg="bg-white" question="Wie viele Sendungen muss ich annehmen?">
            <p className="mb-4">
              Egal ob Du nur eine Sendung aufbewahren magst oder Dich die Sendungsanzahl nicht
              weiter interessiert. Bei DropFriends hast Du die freie Wahl.
            </p>
            <p className="mb-4">
              Und ist Deine angegebene Kapazität tatsächlich einmal ausgeschöpft, sind automatisch
              keine weiteren Buchungen Deines DropPoints mehr möglich, bis mindestens eine der
              Sendungen erfolgreich an den Eigentümer übergeben worden ist.
            </p>
            <p>So stellt DropFriends die Einhaltung Deiner Wünsche sicher.</p>
          </FAQAccordion>
          <FAQAccordion
            question="Wann kommt man denn die Pakete bei mir abholen?"
            headerBg="bg-white">
            <p className="mb-4">
              Als DropPoint steht es Dir frei selbst zu bestimmen wann Du Sendungen an Deine
              DropFriends übergeben möchtest. So kannst Du über die Nutzung Deiner Öffnungszeiten
              frei bestimmen und man wird als Sendungseigentümer bereits vor der Buchung Deines
              DropPoints über die möglichen Abholzeiträume informiert.
            </p>
            <p>
              Sobald also Pakete zu den gewohnten Lieferzeiträumen durch die Paketdienstleister bei
              Dir übergeben worden sind, erhalten die Sendungseigentümer eine
              Echtzeit-Benachrichtigung und wissen bescheid, dass sie ihre Bestellungen zu den von
              Dir angegebenen Abholzeiten übergeben bekommen können.
            </p>
          </FAQAccordion>
          <FAQAccordion
            headerBg="bg-white"
            question="Ladenlokale im Immobilienkomplex integrieren: Was haben die davon?">
            <p className="mb-4">
              Nach Deiner Registrierung kannst Du Dein Ladenlokal mit wenigen Klicks als
              Paketannahmestelle aufschalten. Hast Du bereits mehrere Filialen, dann kannst Du
              selbstverständlich auch diese als DropPoint aktivieren.
            </p>
            <p className="mb-4">
              Pro Filiale bzw. Ladenlokal kannst Du einen Gutschein erstellen und frei bestimmen
              welche Form des Mehrwertes Du bei einer Paketabholung triggern möchtest. Egal ob z.B.
              eine Vergünstigung von 5% an der Kasse berücksichtigt werden soll oder Du beim Einkauf
              einen kostenlosen Kaffee verschenken magst – Deiner Kreativität sind keine Grenzen
              gesetzt. Ganz einfach mit wenigen Klicks.
            </p>
            <p>
              Nachdem man Pakete bei Dir im Ladenlokal überreicht bekommen hat, steht der Gutschein
              in der App sofort zum Abruf bereit – noch während die Kunden bei Dir im Laden sind:
              Einfach bei Dir an der Kasse vorzeigen und mit einem Fingerwisch in der App einlösen.
              Fertig. Dein Kassenpersonal kann den Rabatt an der Kasse direkt für den Einkauf
              verrechnen. Einfach, sicher und in Echtzeit.
            </p>
          </FAQAccordion>
          <RegisterLinkContainer tw={'justify-center mt-12'}>
            <Link
              to={`/anwohner-paketshop#realestate-form`}
              rel="noreferrer"
              className={
                'text-xl font-display font-bold tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer'
              }>
              Infos anfordern
            </Link>
          </RegisterLinkContainer>
        </FAQWrapper>
      </FAQSection>

      <Press />

      <Section title="Warum DropFriends im Stadtquartier und in Immobilien einsetzen?" fullWidth>
        <p className="max-w-4xl mx-auto px-6 text-center mb-16">
          Die Anziehungskraft von Städten liegt in ihrer Gemeinschaft, der Vielfältigkeit, der
          Unterhaltung und den Arbeitsmöglichkeiten. Allerdings geht der Zuzug auch mit
          Herausforderungen einher: Anonymität, Parkplatz- und Flächenmangel, hoher Verkehr durch
          Haushalte, Gewerbe und Lieferdienstleistern sowie unbezahlbarer und unattraktiver
          Wohnraum. Welche Verantwortung tragen Immobilienentwicker, Städte und Eigentümer? Unsere
          unabhängige Plattform zum Paket-Empfangsmanagement unterstützt bei der Transformation zur
          Smart City.
        </p>
        <ImageTextRow leftImageFluid={ImageGutschein} position="left" fullWidth>
          <AdvantageHeader>Smart City: Anwohner und Ladenlokale profitieren</AdvantageHeader>
          <AdvantageContent>
            Haushalte optimieren ihre Haushaltskasse. Ladenlokale steigern Umsätze.
            <br />
            <br />
            Privathaushalte werden auf Wunsch für Paketübergaben vergütet. Das Sammeln positiver
            Bewertungen aus der Community können die Haushaltskasse zusätzlich optimieren. Die
            Abholzeiten und die Paketanzahl werden dabei selbst definiert.
            <br />
            <br />
            Ladenlokale erhöhen ihre Umsätze mit der Annahme von Paketen im Stadtquartier: Mit Hilfe
            von individuellen Gutscheinen, die sie selbst auf der Plattform in wenigen Klicks
            erstellen und bearbeiten können. Die Verkaufsflächen werden nicht belastet, weil die
            Paketanzahl durch die InhaberInnen selbst definiert werden. Auch Abholzeiten werden
            selbst festgelegt.
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={ImagePakete} position="right" fullWidth>
          <AdvantageHeader>Deine Spielregeln</AdvantageHeader>
          <AdvantageContent>
            Bestimme die Anzahl, die Größe und das Gewicht der Paketsendungen die Du annehmen
            möchtest. Vor einer Buchung wird man über Deine Regeln informiert und die Sicherstellung
            funktioniert vollautomatisch.
          </AdvantageContent>
          <RegisterLinkContainerAdvantages>
            <Link
              to={`/anwohner-paketshop#realestate-form`}
              rel="noreferrer"
              className={
                'text-xl font-display font-bold tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer'
              }>
              Infos anfordern
            </Link>
          </RegisterLinkContainerAdvantages>
        </ImageTextRow>
        <ImageTextRow leftImageFluid={Business3} position="left" fullWidth>
          <AdvantageHeader>Sicher ist sicher</AdvantageHeader>
          <AdvantageContent>
            Paketübergaben erfolgen nur nach erfolgreicher Identifizierung: Bestimme selbst ob per
            Fotoabgleich oder Prüfung des Ausweises. Bereits vor der Buchung wird man über die
            Prüfungsart informiert. So werden Wartezeiten bei der Übergabe vermieden.
          </AdvantageContent>
        </ImageTextRow>
        <ImageTextRow rightImageFluid={Sprint3} position="right" fullWidth>
          <AdvantageHeader>Der Umwelt zuliebe</AdvantageHeader>
          <AdvantageContent>
            Als DropPoint bist Du die zentrale Anlaufstelle für alle Boten. Heißt: Du bist nicht auf
            eine Lieferdienst-Marke beschränkt. Mehrere Zustellversuche durch Paketdienstleister
            werden durch die Buchung Deines DropPoints reduziert. Das verringert den CO2-Ausstoß pro
            Paket auf bis zu 277g. Die monatliche Gesamteinsparung errechnen wir Dir automatisch im
            DropFriends-Dashboard, damit Du Deine Kunden mit einer echten Kennzahl über Dein
            Engagement zum Umweltschutz informieren kannst.
          </AdvantageContent>
          <RegisterLinkContainerAdvantages>
            <Link
              to={`/anwohner-paketshop#realestate-form`}
              rel="noreferrer"
              className={
                'text-xl font-display font-bold tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer'
              }>
              Infos anfordern
            </Link>
          </RegisterLinkContainerAdvantages>
        </ImageTextRow>

        <ImageTextRow
          rightImageFluid={Business4}
          // rightImageMobileLast
          leftImageFluid={ImageKleiderstange}
          fullWidth
        />
      </Section>

      <Testimonials
        className="bg-gray-200"
        testimonials={[
          {
            testimonial: (
              <>
                Funktioniert!
                <br />
                In Köln gibts enorm viele Annahmestellen und ich habe meinen gefunden. Probiert‘s
                aus!
              </>
            ),
            name: 'Katrin',
            source: 'Play Store',
          },
          {
            testimonial:
              'Ich kann die App wärmstens empfehlen. Bisher hat alles super geklappt und es kommen dauernd neue Orte hinzu, wo ich was hin liefern kann. Nach dem ganzen Stress, den ich bisher in meinem Leben mit Packstationen hatte, bin ich echt erleichtert, dass sich jemand um eine bessere Lösung bemüht hat.',
            name: 'Dennis ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super!
                <br />
                Dank der App fühle ich mich bzw. meine Pakete gut aufgehoben. Das hier braucht jeder
                und mein Gott, wie einfach alles war. Einfach genial!!!
              </>
            ),
            name: 'Engin ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super App!
                <br />
                Läuft stabil und tut was sie soll.
              </>
            ),
            name: 'Chris ',
            source: 'Play Store',
          },
          {
            testimonial:
              'Finde, das ist eine sehr clevere App, viel Potenzial ist da! Ich habe schon einen DropPoint aufgemacht. Finde die Idee super!',

            name: 'Phillip ',
            source: 'Play Store',
          },
          {
            testimonial: (
              <>
                Super App!
                <br />
                Unbedingt ausprobieren.
              </>
            ),
            name: 'Oleg ',
            source: 'Play Store',
          },
        ]}
        children={
          <div className="w-full flex justify-center mt-8">
            <Link
              to={`/anwohner-paketshop#realestate-form`}
              rel="noreferrer"
              className={
                'text-xl font-display font-bold tracking-wide block py-3 px-12 rounded-lg text-white bg-green-500 hover:bg-green-600 cursor-pointer'
              }>
              Infos anfordern
            </Link>
          </div>
        }
      />

      <RealEstateFormSection />

      <AdvantageRow
        title="DropFriends-App & Online-Software"
        titleId="app"
        className="justify-center">
        <Advantage icon={<HiStarIcon />} title="Schnelle Registrierung"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Für alle Paketdienstleister"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Made in Germany | Köln"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Zentrale Steuerung über Deinen Browser"></Advantage>
        <Advantage icon={<HiStarIcon />} title="Simples Handling per Smartphone-App"></Advantage>
      </AdvantageRow>
    </PageLayout>
  );
};

export const query = graphql`
  {
    Hero1: file(relativePath: { eq: "real-estate/01_hero_real estate_woman_parcel_shopping.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero2: file(relativePath: { eq: "paketshop-werden/02_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero3: file(relativePath: { eq: "paketshop-werden/03_hero_business.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero4: file(relativePath: { eq: "real-estate/hero_cakes and parcels.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Hero5: file(
      relativePath: { eq: "real-estate/05_hero_real estate_freudiger_mann mit paket und handy.png" }
    ) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImageGutschein: file(relativePath: { eq: "paketshop-werden/lp_business_gutschein.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImagePakete: file(relativePath: { eq: "real-estate/immobilienentwickler.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    ImageKleiderstange: file(
      relativePath: { eq: "paketshop-werden/lp_business_kleiderstange.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business3: file(relativePath: { eq: "b2b-business-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Business4: file(relativePath: { eq: "b2b-business-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Sprint3: file(relativePath: { eq: "b2b-sprint-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "50vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default B2BPage;
